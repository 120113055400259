import React from 'react';

/* eslint-disable */
const Zoom = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 21">
	// eslint-disable-next-line max-len
	<path d="M19.92383,0H11.8291a.90039.90039,0,1,0,0,1.80078H17.75L1.80078,17.75V11.8291A.90039.90039,0,0,0,0,11.8291v8.0957a.89541.89541,0,0,0,.06763.335l.00024.00091a.89848.89848,0,0,0,.83252.56446H8.99512a.9004.9004,0,0,0,0-1.80079H3.07324L19.02344,3.07422V8.99609a.90039.90039,0,1,0,1.80078,0V.90039A.90065.90065,0,0,0,19.92383,0Z"/>
	</svg>
);

export default Zoom;
