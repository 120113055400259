import React from 'react';

const MinusIcon = () => (
    <svg
        data-testid='minus-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='15.7998'
        height='1.80078'
        viewBox='0 0 15.7998 1.80078'
    >
        <path d='M14.8999,1.80078h-14A.90039.90039,0,0,1,.8999,0h14a.90039.90039,0,0,1,0,1.80078Z' />
    </svg>
);

export default MinusIcon;
