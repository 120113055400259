import React, { useEffect, useState, FunctionComponent, Children } from 'react';
import styles from '../../../../styles/customSwipeable.scss';
import ArrowSmallIcon from '../../../icons/arrowSmallIcon';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';

export interface ICustomSwiper {
    type: string;
    selectedElementIndex: number;
    // If sliderPreColumn are more than 1(default), columnPreView have to have some value but not 'auto'(default)
    direction?: 'horizontal' | 'vertical';
    columnsPerView?: number;
    slidesPerColumn?: number;
    spaceBetween?: number;
    mousewheel?: boolean;
    enabled: boolean;
    isWWS?: boolean;
    swipingEnabled?: boolean;
    startSwipeCallBack?: (SwiperCore) => void;
    endSwipeCallBack?: (SwiperCore) => void;
}

SwiperCore.use([Mousewheel, Navigation]);
export const CustomSwiper: FunctionComponent<ICustomSwiper> = (props: React.PropsWithChildren<ICustomSwiper>) => {
    const useSwiperRef = (): [HTMLDivElement | null, React.LegacyRef<HTMLDivElement> | undefined] => {
        const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null);
        const ref = React.createRef<HTMLDivElement>();

        useEffect(() => {
            if (ref.current)
                setWrapper(ref.current);
        }, []);

        return [wrapper, ref];
    };
    const [prevEl, leftArrowRef] = useSwiperRef();
    const [nextEl, rightArrowRef] = useSwiperRef();
    const direction = props.direction ?? 'horizontal';
    const slidersPerView = props.columnsPerView ?? 'auto';
    const slidersPerColumn = props.slidesPerColumn ?? 1;
    const spaceBetween = props.spaceBetween ?? 0;
    const childrenArray = Children.toArray(props.children);
    const swipingEnabled = props.swipingEnabled ?? true;
    // eslint-disable-next-line
    const fallbackFunction = () => { }; // TODO: better way to handle optional property
    const swipeStart = props.startSwipeCallBack ?? fallbackFunction;
    const swipeEnd = props.endSwipeCallBack ?? fallbackFunction;
    return (
        <>
            <div
                className={classNames(styles.left_arrow, {
                    [styles.hidden]: !props.enabled,
                    [styles.vertical]: direction === 'vertical',
                    [styles.wws]: props.isWWS,
                })}
                ref={leftArrowRef}
            >
                <ArrowSmallIcon />
            </div>
            {props.enabled ? (
                <Swiper
                    className={classNames(styles.swiper, {
                        [styles.one_row]: slidersPerColumn === 1,

                    })}
                    direction={direction}
                    slidesPerView={slidersPerView}
                    slidesPerColumn={slidersPerColumn}
                    spaceBetween={spaceBetween}
                    mousewheel={props.mousewheel}
                    navigation={{
                        prevEl,
                        nextEl,
                    }}
                    initialSlide={props.selectedElementIndex}
                    onTransitionStart={swipeStart}
                    onTransitionEnd={swipeEnd}
                    allowTouchMove={swipingEnabled}
                    touchReleaseOnEdges={true}
                >
                    {childrenArray.map((child, index) => {
                        return <SwiperSlide key={index}>{child}</SwiperSlide>;
                    })}
                </Swiper>
            ) : (
                <>{props.children}</>
            )}

            <div
                className={classNames(styles.right_arrow, {
                    [styles.hidden]: !props.enabled,
                    [styles.vertical]: direction === 'vertical',
                    [styles.wws]: props.isWWS,
                })}
                ref={rightArrowRef}
            >
                <ArrowSmallIcon />
            </div>
        </>
    );
};
