import React from 'react';

/* eslint-disable*/
const Triangle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 206 174" version="1.1" transform="rotate(180)">
        <g>
        </g>
        <g>
            <path fill='currentColor' opacity="1.00"
                // eslint-disable-next-line max-len
                d=" M 5.08 8.97 C 70.51 9.13 135.94 8.78 201.37 9.15 C 173.43 56.08 144.36 102.34 116.05 149.05 C 111.86 155.80 107.91 162.70 103.40 169.25 C 70.52 115.89 38.00 62.30 5.08 8.97 Z" />
        </g>
    </svg>
);

export default Triangle;
