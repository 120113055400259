import React, { useEffect, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import styles from './modelVariant.scss';
import classNames from 'classnames';
import { IInjectedProps } from '../../../../types';
import { CustomSwiper } from '../customeSwiper/customeSwiper';

export const ModelVariant = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const selectedModelName = store.variantStore?.selectedModel.label;
        let selectedModel = store.variantStore?.selectedModel;
        const availableModels = store.variantStore?.variantSelectionCopy.availableModels;
        const tmpSelectedModelIndex = availableModels?.findIndex((model) => {
            return selectedModelName === model.label;
        });
        const selectedModelIndex = tmpSelectedModelIndex ?? 0;
        const [rows, setRows] = useState(1);
        const [column, setColumn] = useState(1);
        const [maxWidth, setMaxWidth] = useState(0);
        const wrapperRef = useRef<HTMLDivElement>(null);
        const [finishedRender, setFinishedRender] = useState(false);
        useEffect(() => {
            calculateWidth();
            setFinishedRender(true);
        }, []);

        function handleModelClick(event) {
            const modelCode = event.currentTarget.dataset.modelcode;
            const selectedModelInternal = availableModels?.find(
                (availableModel) => availableModel.code == parseInt(modelCode)
            );

            if (selectedModelInternal) {
                selectedModel = { code: selectedModelInternal.code, label: selectedModelInternal.label };
                store.variantStore?.changeSelectedModel(selectedModel);
            }
        }

        function calculateWidth() {
            if (wrapperRef.current) {
                const wrapperChildren = Array.from(wrapperRef.current.children as HTMLCollectionOf<HTMLElement>);
                let maxChildWidth = 0;
                wrapperChildren.forEach((element) => {
                    if (element.offsetWidth > maxChildWidth) maxChildWidth = element.offsetWidth;
                });
                let tempColumn = 1;
                // extra small devices (iphone 5/SE):
                if (maxChildWidth <= 42) maxChildWidth = 42;

                if (wrapperRef.current.clientWidth < 370) {
                    tempColumn = Math.floor((wrapperRef.current.clientWidth - 80) / maxChildWidth);
                } else {
                    if (maxChildWidth > 110 && maxChildWidth <= 170) tempColumn = 2;
                    else if (maxChildWidth <= 110 && maxChildWidth > 80) tempColumn = 3;
                    else if (maxChildWidth <= 80 && maxChildWidth > 42) tempColumn = 4;
                    else if (maxChildWidth <= 42) tempColumn = 5;
                }


                calculateRows(wrapperRef.current.clientWidth, maxChildWidth);
                setMaxWidth(maxChildWidth);
                setColumn(tempColumn);
            }
        }

        function calculateRows(clientWidth, elementWidth) {
            if (store.getIsMobileView) setRows(1);
            else {
                //42 margins for arrows
                //20 margins for element
                const columns = (clientWidth - 42) / (elementWidth + 20);
                if (availableModels.length > columns) {
                    setRows(2);
                } else {
                    setRows(1);
                }
            }
        }

        const modelStyles =
            maxWidth > 0
                ? {
                      width: `${maxWidth}px`,
                      minWidth: `${maxWidth}px`,
                      maxWidth: `${maxWidth}px`,
                  }
                : {};

        return (
            <div role='model_content_wrapper' className={styles.model_content_wrapper}>
                <div role='model_content' className={styles.model_content}>
                    <div role='model_icons' ref={wrapperRef} className={styles.model_icons}>
                        <CustomSwiper
                            type='model'
                            selectedElementIndex={selectedModelIndex}
                            enabled={finishedRender}
                            slidesPerColumn={rows}
                            columnsPerView={column}
                        >
                            {availableModels?.map((availableModel, index) => (
                                <div
                                    key={index}
                                    className={classNames(styles.model_icon, {
                                        'om-selected': selectedModelIndex === index,
                                        [styles.sold_out_model]: !availableModel.salesArticleVariantKey,
                                    })}
                                    title={availableModel.label}
                                    data-modelcode={availableModel.code}
                                    onClick={handleModelClick}
                                    style={modelStyles}
                                >
                                    {availableModel.label}
                                </div>
                            ))}
                        </CustomSwiper>
                    </div>
                    <label className={styles.choosen_model_label}>
                        {store.variantStore?.mainStore.localization.selection}:{' ' + selectedModelName}
                    </label>
                </div>
            </div>
        );
    })
);
