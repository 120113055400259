import React from 'react';
import styles from './articleNumber.scss';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';

function ArticleInfo({ info }: { info: string }) {
    if (!info) {
        return null;
    }

    return <span className={styles.article_info}>{info}</span>;
}

const ArticleNumber = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const titleAddon = store?.orderModuleStore.titleAddon ?? '';
        const title = store?.dataStore.orderModule.articleInfo.title;

        return (
            <div className={styles.article_number_container}>
                <div className={styles.article_number}>
                    #{store?.dataStore.orderModule.articleInfo.salesArticleNo}
                </div>
                <div role='heading' className={styles.article_title}>
                     <h1 className={styles.article_designation}>{title}</h1>
                    <ArticleInfo info={titleAddon} />
                </div>
            </div>
        );
    })
);

export default ArticleNumber;
