export function decodeHTML(value?: string): string | undefined {
    if (value)
        return value.replace(/&#(\d+);/g, (_match, dec) => {
            return String.fromCharCode(dec);
        });
    else
        return value;
}

export function isIOS(): boolean {
    if (typeof document !== 'undefined') return document.getElementsByTagName('html')[0].classList.contains('isIOS');
    else return false;
}

export function formCdnUrl(assetsUrl: string, cdnAssetsUrl: string): string {
    const startIndex = assetsUrl.indexOf('/documents/');
    let assetLocation = 'wf/images_preview/TwoColsRightFullscreen';

    if (cdnAssetsUrl.indexOf('preview') < 0) {
        assetLocation = 'wf/images/TwoColsRightFullscreen';
    }

    const tmpCdnAssetsUrl = cdnAssetsUrl.replace('/pdp', '');

    return `${tmpCdnAssetsUrl}${assetLocation}${assetsUrl.substring(startIndex)}`;
}

export function decodeRtfText(text: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = text;
    return txt.value;
}
