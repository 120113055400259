/* eslint-disable max-len */
import React from 'react';
import defaultStyles from '../../styles/defaults.scss';

const Icon_Pause_3D = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
        <g id="Ebene_2" data-name="Ebene 2">
            <g><circle cx="17.5" cy="17.5" r="17.5" />
                <g id="pause">
                    <path fill={defaultStyles.dpWhite}
                        d="M14.00013,24.76385a.90065.90065,0,0,1-.90039-.90039V11.13592a.90039.90039,0,0,1,1.80078,0V23.86346A.90065.90065,0,0,1,14.00013,24.76385Z" />
                    <path fill={defaultStyles.dpWhite}
                        d="M21.00013,24.76385a.90065.90065,0,0,1-.90039-.90039V11.13592a.90039.90039,0,1,1,1.80078,0V23.86346A.90065.90065,0,0,1,21.00013,24.76385Z" />
                </g>
            </g>
        </g>
    </svg>
);

export default Icon_Pause_3D;
