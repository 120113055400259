import * as React from 'react';
import classNames from 'classnames';
import styles from './mediaFrame.scss';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import Icon_Play_3D from '../../icons/icon_play_3d';
import Icon_Pause_3D from '../../icons/icon_pause_3d';
import Zoom from '../../icons/zoom'

export const showCaseRootContainerId = 'order_modal_media_frame_container';
export const showCaseContainerId = 'order_modal_media_frame';

const MediaFrame = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const mixedMediaStore = store.mixedMediaStore;
        const masterArticleNo = store.dataStore.orderModule.articleInfo.masterArticleNo.toString();
        const colorCode = store.dataStore.variantSelection.selectedColor.code;
        const mediaFrameRef = React.useRef<HTMLDivElement>(null);
        const [mediaFrameHeight, setMediaFrameHeight] = React.useState(null);
        const [scsLoaded, setScsLoaded] = React.useState(false);
        const [zoomedOut, setZoomedOut] = React.useState(false);

        React.useEffect(() => {
            if (window.shell) {
                const payload = {
                    containerId: showCaseContainerId,
                    masterArticleNo,
                    colorCode,
                    fallBackImageUrl: mixedMediaStore.getImage.fullPath,
                };
                window.shell.publishTo('SCS.Init.ThreeSixtyAnimation', payload);
                setScsLoaded(false);
            }
        }, [mixedMediaStore.getImage.fullPath]);

        React.useEffect(() => {
            if (window.shell) {
                window.shell.subscribeTo(
                    'SCS.Hide.Fragment',
                    payload => {
                        if(payload.containerId === showCaseContainerId)
                            mixedMediaStore.manageSCSFragment(payload.hidden);
                    },
                    'SCS.Hide.Fragment'
                );

                window.shell.subscribeTo(
                    'SCS.Animation.WasLoaded',
                    (payload: { containerId: string }) => {
                        if (payload && payload.containerId === showCaseContainerId) {
                            setScsLoaded(true);

                            const calcFrameHeight = !store.isMobileShop && !store.isSidepanel && !mediaFrameHeight &&
                                mediaFrameRef && mediaFrameRef.current;
                            if(calcFrameHeight) {
                                setMediaFrameHeight(mediaFrameRef.current.getBoundingClientRect().height)
                            }

                            // Continue to play animation if it was started before:
                            if(store.play3d) {
                                window.shell.publishTo('SCS.PlayPauseAnimation',
                                    { containerId: showCaseContainerId, play3d: true });
                            }
                        }
                    },
                    'SCS.Animation.WasLoaded'
                );
            }

            return () => {
                window.shell.unsubscribeFrom('SCS.Hide.Fragment', 'SCS.Hide.Fragment');
                window.shell.unsubscribeFrom('SCS.Animation.WasLoaded', 'SCS.Animation.WasLoaded');
            };
        }, []);

        return (
            <div
                data-testid={showCaseRootContainerId}
                className={classNames(styles.order_modal_media_frame_container, { [styles.zoomedOut]: zoomedOut })}
            >
                <div
                    id={showCaseContainerId}
                    data-testid={showCaseContainerId}
                    data-runafterpageloadmobile={true}
                    data-preventautoplay={true}
                    className={styles.order_modal_media_frame}
                    style={{
                        minHeight: mediaFrameHeight,
                        maxHeight: mediaFrameHeight
                    }}
                    ref={mediaFrameRef}
                />
                {store.getIsMobileView && (
                    <div
                        className={styles.zoomButton}
                        onClick={() => {
                            setZoomedOut(!zoomedOut);
                        }}
                    >
                        <Zoom />
                    </div>
                )}
                {scsLoaded && (
                    <div
                        className={styles.playButton}
                        data-testid={'play_button'}
                        onClick={() => {
                            store.togglePlay3D();
                        }}
                    >
                        {store.play3d ? <Icon_Pause_3D /> : <Icon_Play_3D />}
                    </div>
                )}
            </div>
        );
    })
);

export default MediaFrame;
