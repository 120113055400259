import React from 'react';

/* eslint-disable */
const ArrowSmallIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='9.79991' height='19.80091' viewBox='0 0 9.79991 19.80091'>
        <path d='M.89952,19.80091a.90016.90016,0,0,1-.67236-1.498L7.69591,9.90052.22716,1.49818A.9.9,0,1,1,1.57286.30286l8,9a.90014.90014,0,0,1,0,1.19532l-8,9A.89946.89946,0,0,1,.89952,19.80091Z'
        />
    </svg>
);

export default ArrowSmallIcon;
