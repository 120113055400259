import { CSSProperties } from 'react';
import { observable, makeObservable, action, computed } from 'mobx';
import {
    IColor,
    ISizeType,
    ISalesArticleVariantSize,
    ISize,
    IModel,
    IAvailableColor,
    IAvailableModel,
    IVariantSelection,
    PriceMode,
} from './types';
import defaultStyles from '../styles/defaults.scss';
import fitAccuracyWrapperStyles from '../components/orderModal/variantSelection/fitAccuracy/fitAccuracyWrapper.scss';
import { ScrollLock } from '../helper/scrollLock';
import { MainStore } from './MainStore';

const breakpointMobileMinWidth = parseInt(defaultStyles.breakpointMobileMinWidth);
const accuracyHeight = parseInt(fitAccuracyWrapperStyles.fitAccuracyHeight);

const accuracyPointerWidth = parseInt(fitAccuracyWrapperStyles.fitAccuracyPointerWidth);
const accuracyPointerHeight = parseInt(fitAccuracyWrapperStyles.fitAccuracyPointerHeight);

export class VariantStore {
    public mainStore: MainStore;
    public readonly scrollLock = new ScrollLock();
    public variantSelectionCopy: IVariantSelection;
    public variantSelectionCopyForWWS: IVariantSelection;
    public readonly defaultColumnsPerView = 5;

    // @observable:
    // UI
    public isVariantPanelOpen: boolean;
    public variantType: VariantType;
    public allowedInteraction: boolean;
    public panelPosition: { top: number; height: string } | null;
    public displayFittingAccuracy: boolean;
    public hoveredSize: ISalesArticleVariantSize | undefined;
    public hoveredSizeIndex: number | undefined;
    public hoveredSizeElement: HTMLElement | undefined;
    public offsetSizeIndex: number;
    public isVisibleFitAccuracyPointer: boolean;

    constructor(mainStore: MainStore) {
        this.mainStore = mainStore;
        // If we have more than one avaliable color MAINTAINED, showColorSwitcher will be True.
        // showColorSwitcher false can have two cases:
        // 1. No maintained color, then availableColors will have 1 element but with code 0.
        // 2. Has maintained one color, the availableColors also will have 1 element but have code > 0.
        // showColorSwitcher true case can still lead to availableColors to only have 1 element.
        // That means all other colors are sold out.
        this.variantType = mainStore.dataStore.variantSelection.showColorSwitcher
            ? VariantType.Color
            : VariantType.None;
        this.isVariantPanelOpen = false;
        this.panelPosition = null;
        this.variantSelectionCopy = JSON.parse(JSON.stringify(mainStore.dataStore.variantSelection));
        this.variantSelectionCopyForWWS = this.variantSelectionCopy;
        this.allowedInteraction = true;
        this.displayFittingAccuracy = false;
        this.hoveredSize = undefined;
        this.hoveredSizeIndex = undefined;
        this.hoveredSizeElement = undefined;
        this.offsetSizeIndex = 0;
        this.isVisibleFitAccuracyPointer = true;

        makeObservable(this, {
            isVariantPanelOpen: observable,
            panelPosition: observable,
            variantType: observable,
            variantSelectionCopy: observable,
            allowedInteraction: observable,
            displayFittingAccuracy: observable,
            hoveredSize: observable,
            hoveredSizeElement: observable,
            hoveredSizeIndex: observable,
            offsetSizeIndex: observable,
            isVisibleFitAccuracyPointer: observable,
            changeVariantPanelDisplay: action,
            setPanelPosition: action,
            selectedColor: computed,
            changeSelectedColor: action,
            changeSelectedSizeType: action,
            changeSelectedSize: action,
            changeHoveredSizeFittingAccuracy: action,
            changeSelectedModel: action,
            selectedSizeType: computed,
            selectedSize: computed,
            sideImagePath: computed,
            selectedModel: computed,
            resetVariantState: action,
            variantChange: action,
            updateAll: action,
            changeInteraction: action,
            updateVariantSelectionCopy: action,
            changeOffSetSizeIndex: action,
            changeVisibleFitAccuracyPointer: action,
            showSizeAccuracy: computed,
            showColorSwitcher: computed,
        });
    }

    // Normal method:
    public getUrlByAvailableColor(color: IAvailableColor): string {
        const salesArticleVariantKey = color.salesArticleVariantKey.split('-');
        return this.getUrl(salesArticleVariantKey[0], salesArticleVariantKey[1]);
    }

    public anySizeExistForSizeType(sizeType: ISizeType): boolean {
        const existSize = sizeType.sizes.find((size: ISalesArticleVariantSize) => {
            return !!size.salesArticleVariantKey;
        });
        return !!existSize;
    }

    private getUrl(salesArticleNo: string, colorCode: string): string {
        // this.mainStore.dataStore.contextData.originalUrl needed only for startup,
        // when server side rendering is in progress and window object is not yet defined.
        // During that moment it is the only source of truth and it is correct
        const rawUrl =
            typeof window !== 'undefined' ? window.location.href : this.mainStore.dataStore.contextData.originalUrl;
        const originalUrl = new URL(rawUrl);
        const lastItem = originalUrl.pathname.substring(originalUrl.pathname.lastIndexOf('/') + 1);
        const subStrings = lastItem.split('-');
        const colorCodeIndex = subStrings.length - 1;
        const salesArticleNoIndex = subStrings.length - 2;
        subStrings[colorCodeIndex] = colorCode;
        subStrings[salesArticleNoIndex] = salesArticleNo;
        const newLastPath = subStrings.join('-') + '.html';
        const url = new URL(newLastPath, originalUrl);
        const query = new URLSearchParams(originalUrl.search);
        query.forEach((value, key) => {
            url.searchParams.set(key, value);
        });
        return url.href;
    }

    private changeVariantType(variantType?: VariantType) {
        if (typeof variantType !== 'undefined') {
            if (variantType !== VariantType.Kiosk) {
                this.variantType = variantType;
            } else {
                if (this.variantSelectionCopy.showSizeSwitcher) {
                    if (this.variantSelectionCopy.isScrew) {
                        this.variantType = VariantType.Special;
                    } else {
                        this.variantType = VariantType.Size;
                    }
                } else if (this.variantSelectionCopy.showModelSwitcher) {
                    this.variantType = VariantType.Model;
                } else if (this.variantSelectionCopy.showColorSwitcher) {
                    this.variantType = VariantType.Color;
                }
            }
        }
    }

    // @action:
    changeVariantPanelDisplay(open: boolean, variantType?: VariantType, width?: number): void {
        this.changeVariantType(variantType);

        // don't open panel when don't have proper type setup
        if (this.variantType === VariantType.None || this.variantType === VariantType.Kiosk) open = false;

        if (!this.isVariantPanelOpen || !open)
            if (open) {
                this.scrollLock.lock();

                // set panel position:
                if (width) this.panelPosition = this.getPanelPosition(width);
            } else {
                this.scrollLock.unlock();
            }

        this.isVariantPanelOpen = open;
    }

    // @action:
    setPanelPosition(width: number): void {
        this.panelPosition = this.getPanelPosition(width);
    }

    private getPanelPosition(width: number): { top: number; height: string } | null {
        const minDeskTopWidth = breakpointMobileMinWidth;

        const showVariantPanelBelowStickyHeader = width > minDeskTopWidth - 1;
        const adjustStickHeaderHeight = this.mainStore.orderModuleStore.stickHeaderHeight + 50;
        return adjustStickHeaderHeight > 0 && showVariantPanelBelowStickyHeader
            ? { top: adjustStickHeaderHeight, height: `calc(100% - ${adjustStickHeaderHeight}px)` }
            : null;
    }

    changeSelectedColor(updatedSelectedColor: IColor): void {
        if (this.variantSelectionCopy.selectedColor?.code !== updatedSelectedColor.code) {
            const selectedAvailableColor = this.variantSelectionCopy.availableColors.find((color) => {
                return color.code === updatedSelectedColor.code;
            });

            if (selectedAvailableColor) {
                this.variantSelectionCopy.selectedColor = updatedSelectedColor;
                const salesArticleVariantKey = selectedAvailableColor.salesArticleVariantKey.split('-');
                this.variantChange(true, salesArticleVariantKey[0], salesArticleVariantKey[1],
                    salesArticleVariantKey[2]);
            }
        }
    }

    changeSelectedModel(updatedSelectedModel: IModel): void {
        if (this.variantSelectionCopy.selectedModel?.code !== updatedSelectedModel.code) {
            this.variantSelectionCopy.selectedModel = updatedSelectedModel;
            this.updateModel(true);
        }
    }

    changeSelectedSizeType(updatedSelectedSizeType: string): void {
        const sizeType = this.variantSelectionCopy.availableSizeTypes.find((aSizeType) => {
            return aSizeType.sizeTypeName === updatedSelectedSizeType;
        });
        if (sizeType) {
            this.variantSelectionCopy.selectedSizeType = updatedSelectedSizeType;
            const defaultSize = sizeType.sizes.find((size) => {
                return size.salesArticleVariantKey;
            });
            if (defaultSize) this.changeSelectedSize(defaultSize.size);
        }
    }

    changeSelectedSize(updatedSelectedSize: ISize): void {
        if (this.variantSelectionCopy.selectedSize?.code !== updatedSelectedSize.code) {
            this.variantSelectionCopy.selectedSize = updatedSelectedSize;
            this.updateVariantSizeSelected(true);
        }
    }

    changeHoveredSizeFittingAccuracy(
        display: boolean,
        updatedSelectedSize?: ISize,
        updatedSizeElement?: HTMLElement
    ): void {
        if (this.showSizeAccuracy) {
            if (display) {
                let result: ISalesArticleVariantSize | undefined;
                const preselectedSizeType = this.selectedSizeType?.sizes.find((size) => {
                    return size.size.code === updatedSelectedSize?.code;
                });

                if (preselectedSizeType) {
                    result = preselectedSizeType;
                    this.hoveredSizeIndex = this.selectedSizeType?.sizes.findIndex((size) => {
                        return size.size.code === updatedSelectedSize?.code;
                    });

                    if (result?.size.fittingAccuracy)
                        if (result.size.fittingAccuracy < -0.5) result.size.fittingAccuracy = -0.5;
                        else if (result.size.fittingAccuracy > 0.5) result.size.fittingAccuracy = 0.5;

                    this.hoveredSize = result;
                    this.displayFittingAccuracy = true;
                }
                this.hoveredSizeElement = updatedSizeElement;
            } else {
                this.hoveredSize = null;
                this.hoveredSizeElement = null;
                this.displayFittingAccuracy = false;
            }
        }
    }

    changeOffSetSizeIndex(offSet: number) {
        this.offsetSizeIndex = offSet;
    }

    changeVisibleFitAccuracyPointer(visible: boolean) {
        this.isVisibleFitAccuracyPointer = visible;
    }

    resetVariantState() {
        this.variantSelectionCopy = JSON.parse(JSON.stringify(this.mainStore.dataStore.variantSelection));
    }

    private updateVariantSizeSelected(shouldUpdateWholePage: boolean) {
        const salesArticleVariantKey = this.selectedSize?.salesArticleVariantKey.split('-');
        if (salesArticleVariantKey) {
            this.variantChange(
                shouldUpdateWholePage,
                salesArticleVariantKey[0],
                salesArticleVariantKey[1],
                salesArticleVariantKey[2]
            );
        }
    }

    private updateModel(shouldUpdateWholePage: boolean) {
        const selectedAvailableModel = this.variantSelectionCopy.availableModels.find((model) => {
            return model.code === this.selectedModel?.code;
        });
        if (selectedAvailableModel) {
            const splitVariantKey = selectedAvailableModel.salesArticleVariantKey.split('-');
            const salesArticleNo = splitVariantKey[0];
            const colorCode = splitVariantKey[1];
            const sizeCode = splitVariantKey[2];
            this.variantChange(shouldUpdateWholePage, salesArticleNo, colorCode, sizeCode);
        }
    }

    private isDeskTopView(): boolean {
        const width = breakpointMobileMinWidth;
        return window.innerWidth >= width;
    }

    public updateAll() {
        if (this.variantSelectionCopy.selectedModel && this.variantSelectionCopy.selectedModel.code != 0)
            this.updateModel(true);
        else this.updateVariantSizeSelected(true);
    }

    public changeInteraction(allowedInteraction: boolean) {
        this.allowedInteraction = allowedInteraction;
    }

    public updateVariantSelectionCopy(data: IVariantSelection) {
        this.variantSelectionCopy = data;
    }

    public variantChange(shouldUpdateWholePage: boolean, salesArticleNo: string, colorCode: string, sizeCode?: string) {
        this.changeInteraction(false);

        const fetchedData = this.mainStore.fetchedData;
        const payload = {
            priceMode: (this.mainStore.orderModuleStore.withVat ? 'Gross' : 'Net') as PriceMode,
            salesArticleNo,
            masterArticleNo: fetchedData.orderModule.articleInfo.masterArticleNo,
            colorCode: Number(colorCode),
            sizeCode: Number(sizeCode),
        };
        this.mainStore.fetcher.getOrderModalData(
            payload,
            (response) => {
                if (response) {
                    this.mainStore.checkSelectedSizeType(response.variantSelection);
                    this.updateVariantSelectionCopy(response.variantSelection);

                    if (shouldUpdateWholePage) {
                        this.mainStore.variantChanged(response);
                    } else {
                        this.mainStore.dataStore.orderModule.articlePrice = response.orderModule.articlePrice;
                        this.mainStore.dataStore.orderModule.deliveryInformation =
                            response.orderModule.deliveryInformation;
                        this.mainStore.dataStore.variantSelection.selectedSize = response.variantSelection.selectedSize;
                    }
                }
            },
            () => {
                this.resetVariantState();
            },
            () => {
                this.changeInteraction(true);
            }
        );
    }

    // @computed:
    get selectedColor(): IColor {
        return {
            code: this.variantSelectionCopy.selectedColor?.code ?? 0,
            name: this.variantSelectionCopy.selectedColor?.name.split('/').join(' / ') ?? '',
        };
    }

    get selectedModel(): IModel | undefined {
        return this.variantSelectionCopy.selectedModel;
    }

    get selectedSizeType(): ISizeType | undefined {
        const updatedSelectedSizeType = this.variantSelectionCopy.selectedSizeType;
        return this.variantSelectionCopy.availableSizeTypes.find((sizeType) => {
            return sizeType.sizeTypeName === updatedSelectedSizeType;
        });
    }

    get selectedSize(): ISalesArticleVariantSize | undefined {
        let result: ISalesArticleVariantSize | undefined;
        const preselectedSizeType = this.selectedSizeType?.sizes.find((size) => {
            return size.size.code === this.variantSelectionCopy.selectedSize?.code;
        });

        if (preselectedSizeType) result = preselectedSizeType;
        else result = this.selectedSizeType?.sizes[0];

        if (result?.size.fittingAccuracy)
            if (result.size.fittingAccuracy < -0.5) result.size.fittingAccuracy = -0.5;
            else if (result.size.fittingAccuracy > 0.5) result.size.fittingAccuracy = 0.5;

        return result;
    }

    get sideImagePath(): string {
        if (this.selectedModel && this.selectedModel.code != 0) {
            const selectedModel: IAvailableModel | undefined = this.variantSelectionCopy.availableModels.find(
                (model) => {
                    return model.code === this.selectedModel?.code;
                }
            );
            return selectedModel ? selectedModel.defaultArticleImagePath : '';
        } else if (this.selectedColor.code != 0) {
            const selectedColor: IAvailableColor | undefined = this.variantSelectionCopy.availableColors.find(
                (color) => {
                    return color.code === this.selectedColor.code;
                }
            );
            return selectedColor ? selectedColor.defaultArticleImagePath : '';
        }
        return '';
    }

    get showSizeAccuracy(): boolean {
        // Fitting Accuracy can be 0 and its a valid data to present
        return (
            this.selectedSize?.size.fittingAccuracy !== null && this.selectedSize?.size.fittingAccuracy !== undefined
        );
    }

    get accuracyPosition(): { wrapper: CSSProperties; pointer: CSSProperties } {
        let result = { wrapper: null, pointer: null };
        const hoveredSizePosition = this.hoveredSizeElement && this.hoveredSizeElement.getBoundingClientRect();

        if (hoveredSizePosition) {
            result = {
                wrapper: { top: hoveredSizePosition.top - accuracyHeight - accuracyPointerHeight },
                pointer: {
                    left: hoveredSizePosition.left + hoveredSizePosition.width / 2 - accuracyPointerWidth / 2,
                },
            };
        }

        return result;
    }

    get showColorSwitcher(): boolean {
        const variantSelection = this.variantSelectionCopy;
        return variantSelection?.showColorSwitcher || (variantSelection?.availableColors.length > 0 &&
            !(variantSelection.availableColors.length === 1 && variantSelection.availableColors[0].code === 0));
    }
}

export enum VariantType {
    Color,
    Size,
    Special,
    Model,
    None,
    Kiosk, // special type for kiosk only. If we have color, show color panel. Otherwise, show size panel
}
