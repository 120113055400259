import { action, makeObservable, observable } from 'mobx';
import { MainStore } from './MainStore';
import {
    IBookmarkArticleData,
    IMixedMediaData,
    IOrderModuleData,
    IResponseData,
    IVariantSelection,
    IContextData,
    IBreadcrumbData,
} from './types';

class DataStore {
    mainStore: MainStore;

    contextData: IContextData;
    mixedMedia: IMixedMediaData;
    orderModule: IOrderModuleData;
    variantSelection: IVariantSelection;
    bookmark: IBookmarkArticleData;
    breadcrumb: IBreadcrumbData;

    constructor(initialData: IResponseData, store: MainStore) {
        this.mainStore = store;
        this.updateData(initialData);

        makeObservable(this, {
            contextData: observable,
            mixedMedia: observable,
            orderModule: observable,
            variantSelection: observable,
            bookmark: observable,
            updateData: action.bound,
        });
    }

    updateData(data: IResponseData) {
        this.contextData = data.contextData;
        this.mixedMedia = data.mixedMedia;
        this.orderModule = data.orderModule;
        this.orderModule.articlePrice.scalePrices = data.orderModule.articlePrice.scalePrices?.slice().sort(
            (a, b) => a.quantity - b.quantity);
        this.variantSelection = data.variantSelection;
        if (this.mainStore.variantStore) {
            this.mainStore.variantStore.variantSelectionCopy = data.variantSelection;
        }
        this.bookmark = data.bookmark;
        this.breadcrumb = data.breadcrumb;
    }
}

export default DataStore;
