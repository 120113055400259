import React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './sizeValue.scss';
import { IInjectedProps } from '../../../../../types';
import { ISize } from '../../../../../stores/types';

export const SizeValue = inject('store')(
    observer(({ store, size, variantKey, maxWidth }: IInjectedProps & ISizeValueProps) => {
        const variantStore = store.variantStore;
        const sizeRef = React.useRef<HTMLDivElement>(null);
        const [isHovering, setIsHovering] = React.useState(false);
        const hoverRef = React.useRef(null);
        hoverRef.current = isHovering;

        React.useEffect(() => {
            if (maxWidth > 0 && sizeRef.current) {
                sizeRef.current.style.width = maxWidth + 'px';
                sizeRef.current.style.minWidth = maxWidth + 'px';
                sizeRef.current.style.maxWidth = maxWidth + 'px';
            }
        });
        function handleSelectedSizeChangeClick() {
            variantStore.changeSelectedSize(size);
        }

        function sizeValueClassNames(): string {
            const classNames: string[] = [styles.size_value];

            if (variantStore.selectedSize?.size.code == size.code)
                classNames.push(styles.active);
            if (!variantKey)
                classNames.push(styles.sold_out_size);

            return classNames.join(' ');
        }

        function showFittingAccuracy() {
            setIsHovering(true);
            if (!store.isMobileShop) {
                setTimeout(() => {
                    const hovered = hoverRef.current; // setTimeout does not know current state, so we use a ref here
                    if (hovered)
                        variantStore.changeHoveredSizeFittingAccuracy(true, size, sizeRef.current)
                }, 1000);
            }
        }

        function hideFittingAccuracy() {
            setIsHovering(false);
            variantStore.changeHoveredSizeFittingAccuracy(false);
        }

        return (
            <div data-testid='size_value' data-savkey={variantKey}
                className={sizeValueClassNames()}
                onClick={handleSelectedSizeChangeClick}
                onMouseEnter={showFittingAccuracy}
                onMouseLeave={hideFittingAccuracy}
                ref={sizeRef}
            >
                {size.name}
            </div>
        );
    })
);

export interface ISizeValueProps {
    size: ISize;
    variantKey: string;
    maxWidth?: number;
}
