import { observable, makeObservable, action, computed } from 'mobx';
import { MainStore } from './MainStore';
import { IImage, IMixedMediaData, SalesArticleType } from './types';
import { IAssetsConfig } from '../types';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const assetsConfig = require('../../../../assetsconfig/AssetsConfig.json') as IAssetsConfig;

export class MixedMediaStore {
    public mainStore: MainStore;
    // general data:
    public masterArticleNo: number;
    public colorCode: number;
    public masterArticleNoDefault: number;
    public colorCodeDefault: number;
    public salesArticleType: SalesArticleType;

    public isNew: boolean;
    public mainImage: IImage;

    public temporaryHideSCS: boolean;
    public fallBackImageUrl: string;

    //--

    constructor(mainStore: MainStore) {
        this.mainStore = mainStore;
        this.isNew = mainStore.dataStore.mixedMedia?.isNew;
        this.mainImage = mainStore.dataStore.mixedMedia?.image;
        this.salesArticleType = mainStore.dataStore.mixedMedia?.salesArticleType;

        this.masterArticleNoDefault = this.masterArticleNo = mainStore.dataStore.mixedMedia?.masterArticleNo;
        this.colorCodeDefault = this.colorCode = mainStore.dataStore.mixedMedia?.colorCode;
        this.fallBackImageUrl = this.mainImage.fullPath;
        this.temporaryHideSCS = false;

        makeObservable(this, {
            masterArticleNo: observable,
            colorCode: observable,
            isNew: observable,
            mainImage: observable,
            temporaryHideSCS: observable,
            getImage: computed,
            dataUpdate: action,
            manageSCSFragment: action,
            changeMainImage: action,
        });
    }

    dataUpdate(mixedMediaData: IMixedMediaData): void {
        this.isNew = mixedMediaData?.isNew;
        this.mainImage = mixedMediaData?.image;

        this.masterArticleNo = mixedMediaData?.masterArticleNo;
        this.colorCode = mixedMediaData?.colorCode;
    }

    get getImage() {
        const mainImage = this.mainImage;
        const fullPath = this.mainImage.fullPath
            ?.replace(
                assetsConfig.Scales[0].Name,
                !this.mainStore.isMobileShop ? assetsConfig.Scales[1].Name : assetsConfig.Scales[2].Name
            )
            .replace('/pdp/', `/${assetsConfig.ApplicationName}/`);
        return { ...mainImage, fullPath };
    }

    manageSCSFragment(hidden: boolean): void {
        this.temporaryHideSCS = hidden;
    }

    changeMainImage(newImage: IImage) {
        this.mainImage = newImage;
    }
}
