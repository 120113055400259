import React from 'react';
import { inject, observer } from 'mobx-react';
import { IToDetailsPageButtonProps } from '../../../types';
import styles from './toDetailsPageButton.scss';

const ToDetailsPageButton = inject('store')(
    observer(({ store, handleIsOpenChange }: IToDetailsPageButtonProps) => {
        function toDetailsPage() {
            const currentUrl = window.location.href;
            const url = new URL(store.dataStore.contextData.detailsPageUrl.toLowerCase());

            if (store.orderModuleStore.itemOrigin.length > 0) {
                if (url.searchParams.has('itemOrigin'))
                    url.searchParams.set('itemOrigin', store.orderModuleStore.itemOrigin);
                else
                    url.searchParams.append('itemOrigin', store.orderModuleStore.itemOrigin);
            }

            if (currentUrl === url.toString())
                handleIsOpenChange(false);
            else
                window.location.href = url.toString();

        }

        if (!store.dataStore.contextData.pdpIsAlive) {
            return null;
        }

        return (
            <div className={styles.to_detailsPage_button}>
                <button onClick={toDetailsPage}>{store.localization.toTheDetailsPage}</button>
            </div>
        );
    })
);

export default ToDetailsPageButton;
