import React from 'react';
/* eslint-disable */
function ShareIcon() {
    return (
        <svg role='img' xmlns="http://www.w3.org/2000/svg" width="20.34961" height="21.80078" viewBox="0 0 20.34961 21.80078">
            <path d="M16.15039,13.40234a4.17436,4.17436,0,0,0-3.14746,1.44825L8.18774,12.15131a4.142,4.142,0,0,0,.21167-1.25092A4.14407,4.14407,0,0,0,8.2002,9.68964l4.82812-2.70758A4.1801,4.1801,0,1,0,11.9502,4.2002a4.14257,4.14257,0,0,0,.19921,1.21051L7.321,8.11835a4.1988,4.1988,0,1,0-.02636,5.59649l4.84277,2.71466a4.14017,4.14017,0,0,0-.18725,1.17206,4.19971,4.19971,0,1,0,4.20019-4.19922Zm0-11.60156A2.39893,2.39893,0,1,1,13.751,4.2002,2.40167,2.40167,0,0,1,16.15039,1.80078ZM4.19922,13.2998a2.39893,2.39893,0,1,1,2.39941-2.39941A2.40166,2.40166,0,0,1,4.19922,13.2998ZM16.15039,20a2.39844,2.39844,0,1,1,2.39844-2.39844A2.40166,2.40166,0,0,1,16.15039,20Z" />
        </svg>
    );
}

export default ShareIcon;
