import React from 'react';
import styles from './orderModalContainer.scss';
import { IInjectedProps } from '../../../types';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import OrderModalDataProvider from '../orderModalDataProvider/orderModalDataProvider';
import { IOrderModalContainer } from './orderModalContainer.d';

export const OrderModalContainer = (props: IInjectedProps & IOrderModalContainer) => {
    const { isOpen, isError, isLoading, initialData, initialScrollPosition, handleIsOpenChange, itemOrigin,
        contentContainerId, viewType, slidingOut } = props;

    if (!isOpen || isError) {
        return null;
    }

    if (isLoading && !initialData) {
        return <LoadingSpinner />;
    }

    return (
        <div data-testid='order_modal' className={styles.order_modal}>
            <div className={styles.order_modal_container}>
                <OrderModalDataProvider
                    isLoading={isLoading}
                    initialData={initialData}
                    initialScrollPosition={initialScrollPosition}
                    handleIsOpenChange={handleIsOpenChange}
                    itemOrigin={itemOrigin}
                    contentContainerId={contentContainerId}
                    viewType={viewType}
                    slidingOut={slidingOut}
                />
            </div>
        </div>
    );
};

export default OrderModalContainer;
