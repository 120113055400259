import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { IVariantStore } from '../../../../types';
import classNames from 'classnames';
import * as helper from '../../../../helper/html-helper';
import styles from './fitAccuracy.scss';
import Triangle from '../../../../components/icons/triangle';

export const FitAccuracy = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const fitAccuracyMarkerID = 'fit_accuracy_marker_id';
        const fitAccuracyScalesID = 'fit_accuracy_scales_id';
        useEffect(() => {
            if (variantStore.mainStore.isMobileShop && !variantStore.hoveredSize) {
                variantStore.changeHoveredSizeFittingAccuracy(true, variantStore.selectedSize.size);
            }
            
            if (variantStore.showSizeAccuracy && variantStore.hoveredSize) {
                // Setting marker position on fit_accuracy_scale
                const marker = document.getElementById(fitAccuracyMarkerID);
                const fit_accuracy_scales = document.getElementById(fitAccuracyScalesID);

                if (marker && fit_accuracy_scales) {
                    const halfScaleWidth = fit_accuracy_scales.offsetWidth / 2;
                    const markerPosition = (halfScaleWidth *
                        variantStore.hoveredSize.size.fittingAccuracy) / 0.5;
                    marker.style.marginLeft = halfScaleWidth + markerPosition + 'px';
                }
            }
        }, [variantStore.hoveredSize]);

        return (
            <>
                <label className={styles.fit_accuracy_lable}>
                    {helper.decodeHTML(
                        variantStore.mainStore.localization.fittingAccuracy
                    )}
                </label>
                <div className={styles.fit_accuracy_container}>
                    <div className={classNames(styles.fit_accuracy_border, styles.border_left)} />
                    <div id={fitAccuracyScalesID} className={styles.fit_accuracy_scales}>
                        <div className={styles.fit_accuracy_scale} />
                        <div className={styles.fit_accuracy_scale} />
                        <div className={styles.fit_accuracy_scale} />
                    </div>
                    <div id={fitAccuracyMarkerID} className={styles.marker}>
                        <Triangle />
                    </div>
                    <div className={classNames(styles.fit_accuracy_border, styles.border_right)} />
                </div>
                <table className={styles.section_headlines}>
                    <tbody>
                        <tr>
                            <td>
                                {helper.decodeHTML(
                                    variantStore.mainStore.localization.smallerSize
                                )}
                            </td>
                            <td>
                                {helper.decodeHTML(
                                    variantStore.mainStore.localization.matchingSize
                                )}
                            </td>
                            <td>
                                {helper.decodeHTML(
                                    variantStore.mainStore.localization.largerSize
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </>
        );
    })
);
