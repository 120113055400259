import React from 'react';

/* eslint-disable */
function SwitcherIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' data-testid='switcher-icon'>
            <path fill='white' d='M10,0A10,10,0,1,0,20,10,9.99993,9.99993,0,0,0,10,0Z' />
        </svg>
    );
}

export default SwitcherIcon;
