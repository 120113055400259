export class ScrollLock {
    public isLocked = false;

    // tslint:disable-next-line: variable-name
    private static _instance: ScrollLock;
    private bodyScrollPosition = 0;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    private static isIOS(): boolean {
        return (
            document.documentElement.classList.contains('ios') ||
            /(iPad|iPhone|iPod)/g.test(navigator.userAgent) ||
            (navigator.userAgent.includes('Mac') && navigator.maxTouchPoints > 1)
        );
    }

    public lock(): void {
        if (!this.isLocked) {
            const webContainer = document.querySelector('.container');
            const mobileContainer = document.querySelector('.page-container');
            this.bodyScrollPosition = window.scrollY;

            if (mobileContainer || ScrollLock.isIOS()) {
                document.body.style.setProperty('overflow', 'hidden', 'important');
                document.body.style.setProperty('overscroll-behavior', 'none;');
                document.body.style.setProperty('position', 'fixed', 'important');
                document.body.style.setProperty('top', '-' + this.bodyScrollPosition.toString() + 'px;');
                this.isLocked = true;
            } else if (webContainer) {
                document.body.style.setProperty('overflow', 'hidden', 'important');
                document.body.style.setProperty('position', 'static', 'important');
                this.isLocked = true;
            }
        }
    }

    public unlock(): void {
        if (this.isLocked) {
            const webContainer = document.querySelector('.container');
            const mobileContainer = document.querySelector('.page-container');

            if (mobileContainer || ScrollLock.isIOS()) {
                document.body.style.removeProperty('overflow');
                document.body.style.removeProperty('overscroll-behavior');
                document.body.style.removeProperty('position');
                document.body.style.removeProperty('top');
                window.scrollTo(0, this.bodyScrollPosition);
                this.isLocked = false;
            } else if (webContainer) {
                document.body.style.removeProperty('overflow');
                document.body.style.removeProperty('position');
                this.isLocked = false;
            }
        }
    }
}
