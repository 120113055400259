import React from 'react';

/* eslint-disable */
function BasketIcon() {
    return (
        <svg role='img' xmlns="http://www.w3.org/2000/svg" width="20.99776" height="22.3999" viewBox="0 0 20.99776 22.3999">
            <path d="M20.36426,9.856a.89662.89662,0,0,0-1.126.59228l-1.09766,3.53369H7.55371L5.209,2.51953a.90014.90014,0,0,0-.88183-.71973H.90039a.89991.89991,0,1,0,0,1.79981H3.59277L5.9375,15.062a.90014.90014,0,0,0,.88184.71973H18.80371a.89963.89963,0,0,0,.85938-.63281L20.957,10.98242A.9.9,0,0,0,20.36426,9.856Z" /><path d="M16.37891,17.31152A2.54419,2.54419,0,1,0,18.92383,19.856,2.54744,2.54744,0,0,0,16.37891,17.31152Zm0,3.688A1.1438,1.1438,0,1,1,17.52344,19.856,1.14446,1.14446,0,0,1,16.37891,20.99951Z" /><path d="M9.51074,17.31152a2.54419,2.54419,0,1,0,2.544,2.54444A2.54743,2.54743,0,0,0,9.51074,17.31152Zm0,3.688A1.1438,1.1438,0,1,1,10.6543,19.856,1.14445,1.14445,0,0,1,9.51074,20.99951Z" /><path d="M12.81129,9.23145a.68288.68288,0,0,0,.10926.06384.67469.67469,0,0,0,.09167.05359.65813.65813,0,0,0,.51062-.00006.68688.68688,0,0,0,.09058-.053.67411.67411,0,0,0,.11-.06433l3.11133-2.667a.70015.70015,0,0,0-.91211-1.0625L13.96754,7.178V.7002a.7002.7002,0,1,0-1.40039,0V7.1778L10.61305,5.502a.70015.70015,0,0,0-.91211,1.0625Z" />
        </svg>
    );
}

export default BasketIcon;
