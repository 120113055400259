import * as React from 'react';
import classNames from 'classnames';
import CrossIcon from '../icons/crossIcon';
import styles from './modalWrapper.scss';

function ModalWrapper(props: IModalWrapper) {
    if (!props.isOpen) {
        return null;
    }

    const contentClassNames = classNames(styles.modal_content,
        typeof props.contentClassNames === 'string' ? props.contentClassNames  : '',
        Array.isArray(props.contentClassNames) ? props.contentClassNames.join(' ')  : '',
    );

    const titleClassNames = classNames(styles.modal_title,
        typeof props.titleClassNames === 'string' ? props.titleClassNames  : '',
        Array.isArray(props.titleClassNames) ? props.titleClassNames.join(' ')  : '',
    );

    return (
        <>
            <div className={styles.modal_wrapper}>
                <div className={styles.modal_container}>
                    <div className={contentClassNames}>
                        <div className={styles.modal_header}>
                            <div className={titleClassNames}>
                                <div
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{ __html: props.title }}
                                />
                                {props.subtitle && (
                                    <div
                                        className={styles.subtitle}
                                        dangerouslySetInnerHTML={{ __html: props.subtitle }}
                                    />
                                )}
                            </div>

                            <div
                                className={styles.close_button}
                                data-testid='close_button_header'
                                onClick={props.onClose}
                            >
                                <CrossIcon />
                            </div>
                        </div>
                        <div className={styles.modal_body}>{props.content}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalWrapper;

interface IModalWrapper {
    isOpen: boolean;
    contentClassNames?: string | string[];
    title: string;
    titleClassNames?: string | string[];
    subtitle?: string;
    content: React.ReactNode;

    onClose();
}
