import React from 'react';
import { observer, inject } from 'mobx-react';
import { IInjectedProps } from '../../../../../types';
import classNames from 'classnames';
import styles from './sizeDimension.scss';
import { ISizeType } from '../../../../../stores/types';

export const SizeDimension = inject('store')(
    observer(({ store, sizeType }: IInjectedProps & ISizeDimensionProps) => {
        const handleSizeDimensionClick = (event: React.MouseEvent<HTMLDivElement>) => {
            if (event.currentTarget.dataset.dimension) {
                store.variantStore.changeSelectedSizeType(event.currentTarget.dataset.dimension);
            }
        };

        const isSizeDimensionInactive = sizeType.sizes.every(size => {
            return size.salesArticleVariantKey == '';
        });

        return (
            <div role='size_dimension' className={classNames(styles.size_dimension, {
                [styles.active]:
                    store.variantStore.selectedSizeType?.sizeTypeName ===
                    sizeType.sizeTypeName && !isSizeDimensionInactive,
                [styles.size_dimension_inactive]: isSizeDimensionInactive,
            })}
                data-dimension={sizeType.sizeTypeName}
                onClick={handleSizeDimensionClick}>
                <div className={styles.selected_size_dimension_label}>{sizeType.sizeTypeName}</div>
            </div>
        );
    })
);

export interface ISizeDimensionProps {
    sizeType: ISizeType;
}
