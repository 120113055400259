import React from 'react';

/* eslint-disable */
const Length = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.59961" height="17.86536" viewBox="0 0 26.59961 17.86536">
        <path d="M25.7998,5.93958H24.68652a2.79447,2.79447,0,0,0-2.792,2.792V9.1261H4.07812a.8037.8037,0,0,0-.42675.12305L.37305,11.31458a.80038.80038,0,0,0,0,1.35351l3.27832,2.06445a.79752.79752,0,0,0,.42675.123H21.89453v.21777a2.79448,2.79448,0,0,0,2.792,2.792H25.7998a.80021.80021,0,0,0,.79981-.79981V6.73938A.8002.8002,0,0,0,25.7998,5.93958ZM4.30859,13.256,2.30078,11.99133l2.00879-1.26562h17.585V13.256ZM25,16.26575h-.31348a1.19407,1.19407,0,0,1-1.19238-1.19239V8.73157a1.19407,1.19407,0,0,1,1.19238-1.19239H25Z" />
        <path d="M.10181,3.17847l-.0022.01111.0022.0111a.6989.6989,0,0,0,.04992.24683.67431.67431,0,0,0,.058.09607.66192.66192,0,0,0,.06665.11084L2.48926,6.14368A.7.7,0,1,0,3.53613,5.214L2.35889,3.88977h17.124L18.30566,5.214a.70005.70005,0,1,0,1.04688.92969l2.21289-2.48926a.70034.70034,0,0,0,0-.92969L19.35254.23547a.70005.70005,0,0,0-1.04688.92969l1.17725,1.32422H2.35889L3.53613,1.16516A.7.7,0,0,0,2.48926.23547L.27637,2.72473a.65987.65987,0,0,0-.06665.1106.6823.6823,0,0,0-.058.09619A.69954.69954,0,0,0,.10181,3.17847Z" />
    </svg>
);

export default Length;
