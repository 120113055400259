import React from 'react';

/* eslint-disable */
const PlusIcon = () => (
    <svg
        data-testid='plus-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='15.7998'
        height='15.80078'
        viewBox='0 0 15.7998 15.80078'
    >
        <path d='M14.8999,7H8.7998V.90039A.8999.8999,0,1,0,7,.90039V7H.8999a.90039.90039,0,0,0,0,1.80078H7v6.09961a.8999.8999,0,1,0,1.7998,0V8.80078h6.1001A.90039.90039,0,0,0,14.8999,7Z' />
    </svg>
);

export default PlusIcon;
