import React from 'react';
import defaultStyles from '../../styles/defaults.scss';

/* eslint-disable */
function StatusIcon(props: IStatusIcon) {
    const { statusColor } = props;
    return (
        <svg role='img' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <g>
                <g>
                    <g>
                        <path
                            data-testid='icon-path'
                            style={{ fill: statusColor }}
                            d='M12,1.8A10.2,10.2,0,1,1,1.80005,12,10.21153,10.21153,0,0,1,12,1.8M12,0A12,12,0,1,0,24,12,12.00014,12.00014,0,0,0,12,0Z'
                        />
                        <path
                            data-testid='icon-path'
                            style={{ fill: statusColor }}
                            d='M12.01978,8.0489A1.20021,1.20021,0,0,1,12.01,5.64851h.00977a1.2002,1.2002,0,0,1,0,2.40039Z'
                        />
                        <path
                            data-testid='icon-path'
                            style={{ fill: statusColor }}
                            d='M12.00024,18.74861a.99974.99974,0,0,1-1-1v-.019c.002-.27051.002-6.18506,0-6.45557v-.019a.99974.99974,0,0,1,1-1c1,0,1.001.7583,1.001,2.01416l.001,2.23242-.001,2.23291C13.00122,17.99031,13.00024,18.74861,12.00024,18.74861Z'
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

StatusIcon.defaultProps = {
    statusColor: defaultStyles.dpBlack,
};

interface IStatusIcon {
    statusColor: string;
}

export default StatusIcon;
