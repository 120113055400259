import React from 'react';
import styles from './closeButton.scss';
import CrossIcon from '../../../Assets/svg/crossIcon';

function CloseButton({ handleIsOpenChange, title }: { handleIsOpenChange(isOpen:boolean), title: string }) {
    function handleCloseClick(evt) {
        evt.preventDefault();
        handleIsOpenChange(false);
    }
    
    return (
        <div className={styles.close_button_container}>
            <button className={styles.close_button} onClick={handleCloseClick} data-testid='close_button'
            title={title}>
                <CrossIcon />
            </button>
        </div>
    )
}

export default CloseButton;
