import * as React from 'react';
import OrderModalContainer from './orderModalContainer/orderModalContainer';
import { ScrollLock } from '../../helper/scrollLock';
import Fetcher from '../../stores/Fetcher';
import { IOrderModalAppShellPayLoad, IResponseData, IOrderModalMagicLinkPayLoad, ViewType } from '../../stores/types';
import { EsAppHelper } from '../../helper/esAppHelper';
import NotAvailableModal from '../notAvailableModal/notAvailableModal';

function OrderModal(props: IOrderModal) {
    const scrollLock = React.useMemo(() => new ScrollLock(), []);
    const stylesSaved = React.useRef<boolean>(false);
    const documentBodyStylesRef = React.useRef<string>(null);
    const webContainerStylesRef = React.useRef<string>(null);
    const mobileContainerStylesRef = React.useRef<string>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [viewType, setViewType] = React.useState<ViewType>('modal');
    const [slidingOut, setSlidingOut] = React.useState(false);
    const [appShellNotAvailableModal, setAppShellShowNotAvailableModal] = React.useState(false);
    const [itemOrigin, setItemOrigin] = React.useState('');
    const [contentContainerId, setContentContainerId] = React.useState(null);
    const [initialData, setInitialData] = React.useState<IResponseData>(null);
    const [initialScrollPosition, setInitialScrollPosition] = React.useState(0);
    const esAppHelper: EsAppHelper = new EsAppHelper();

    const handleFetchSuccess = React.useCallback(
        (response: IResponseData, itemOrigin: string, contentContainerId: string) => {
        if (response && response.state.name.toLowerCase() === 'actual') {
            setInitialData(response);
            setIsError(false);
            setItemOrigin(itemOrigin ? itemOrigin: null);
            setContentContainerId(contentContainerId ? contentContainerId : null);
            window.shell.publishTo('ESPP.OrderModal.WillOpen', { success: true });
            handleIsOpenChange(true);
        } else {
            handleFetchFailure();
        }
    }, []);

    const handleFetchFailure = React.useCallback(() => {
        window.shell.publishTo('ESPP.OrderModal.WillOpen', { success: false });
        setIsError(true);
        handleNotAvailableModalChange(true);
    }, []);

    const handleFetchFinally = React.useCallback(() => {
        setIsLoading(false);
    }, []);

    React.useEffect(() => {
        const fetcher = new Fetcher(props.languagePrefix);
        if (window.shell) {
            window.shell.subscribeTo(
                'ESPP.OrderModal.Open',
                (payload: IOrderModalAppShellPayLoad) => {
                    setShowModal(true);
                    setIsLoading(true);
                    payload.viewType && setViewType(payload.viewType);
                    fetcher.getOrderModalData(
                        payload,
                        (response) => {
                            handleFetchSuccess(response, payload.itemOrigin, payload.contentContainerId);
                        },
                        handleFetchFailure,
                        handleFetchFinally
                    );
                },
                'ESPP.OrderModal.Open'
            );

            window.shell.subscribeTo(
                'ESPP.OrderModal.MagicLink.Open',
                (payload: IOrderModalMagicLinkPayLoad) => {
                    setShowModal(true);
                    setIsLoading(true);
                    payload.viewType && setViewType(payload.viewType);
                    fetcher.getOrderModalDataViaMagicLink(
                        payload,
                        (response) => {
                            handleFetchSuccess(response, response.itemOrigin, payload.contentContainerId);
                        },
                        handleFetchFailure,
                        handleFetchFinally
                    );
                },
                'ESPP.OrderModal.MagicLink.Open'
            );

            window.shell.subscribeTo(
                'ESPP.OrderModal.Close',
                () => {
                    handleIsOpenChange(false);
                },
                'ESPP.OrderModal.Close'
            );

            window.shell.subscribeTo(
                'ESPP.OrderModal.NotAvailableModal.Open',
                () => handleNotAvailableModalChange(true),
                'ESPP.OrderModal.NotAvailableModal.Open'
            );
            window.shell.subscribeTo(
                'ESPP.OrderModal.NotAvailableModal.Close',
                () => handleNotAvailableModalChange(false),
                'ESPP.OrderModal.NotAvailableModal.Close'
            );
        }

        return () => {
            if (window.shell) {
                window.shell.unsubscribeFrom('ESPP.OrderModal.Open', 'ESPP.OrderModal.Open');
                window.shell.unsubscribeFrom('ESPP.OrderModal.Close', 'ESPP.OrderModal.Close');
                window.shell.unsubscribeFrom(
                    'ESPP.OrderModal.NotAvailable.Open',
                    'ESPP.OrderModal.NotAvailableModal.Open'
                );
                window.shell.unsubscribeFrom(
                    'ESPP.OrderModal.NotAvailable.Close',
                    'ESPP.OrderModal.NotAvailableModal.Close'
                );
            }
        };
    }, []);

    const handleIsOpenChange = React.useCallback((isOpen: boolean) => {
        function close() {
            setShowModal(false);
            scrollLock.unlock();
            restoreContainerStyles();
            esAppHelper.navigationWheel.show();

            setInitialData(null);
        }

        if (isOpen) {
            setShowModal(true);
            saveContainerStyles();
            setInitialScrollPosition(window.scrollY);
            scrollLock.lock();
            esAppHelper.navigationWheel.hide();
        } else {
            if (viewType === 'sidepanel') {
                setSlidingOut(true);
                setTimeout(() => {
                    close();
                    setSlidingOut(false);
                }, 400);
            } else {
                close();
            }
        }
    }, [viewType]);

    function handleNotAvailableModalChange(isOpen: boolean) {
        handleIsOpenChange(isOpen);
        setAppShellShowNotAvailableModal(isOpen);

        if (!isOpen) {
            setIsError(false);
        }
    }

    function saveContainerStyles() {
        if (!stylesSaved.current && typeof document !== 'undefined') {
            stylesSaved.current = true;
            documentBodyStylesRef.current = document.body.style.cssText;

            const webContainer = document.querySelector('.container');
            if (webContainer) {
                webContainerStylesRef.current = webContainer.getAttribute('style');
            } else {
                const mobileContainer = document.querySelector('.page-container');
                if (mobileContainer) {
                    mobileContainerStylesRef.current = mobileContainer.getAttribute('style');
                }
            }
        }
    }

    function restoreContainerStyles() {
        if (typeof document !== 'undefined') {
            document.body.setAttribute('style', documentBodyStylesRef.current);

            if (webContainerStylesRef.current) {
                document.querySelector('.container')?.setAttribute('style',
                    webContainerStylesRef.current);
            } else if (mobileContainerStylesRef.current) {
                document.querySelector('.page-container')?.setAttribute('style',
                    mobileContainerStylesRef.current);
            }
        }
        documentBodyStylesRef.current = '';
        webContainerStylesRef.current = '';
        mobileContainerStylesRef.current = '';
        stylesSaved.current = false;
    }

    const showNotAvailableModal = isError || appShellNotAvailableModal;

    return (
        <>
            {showNotAvailableModal ? (
                <NotAvailableModal isOpen={showNotAvailableModal} onClose={handleNotAvailableModalChange} />
            ) : (
                <OrderModalContainer
                    isOpen={showModal}
                    isError={isError}
                    isLoading={isLoading}
                    initialData={initialData}
                    initialScrollPosition={initialScrollPosition}
                    itemOrigin={itemOrigin}
                    viewType={viewType}
                    slidingOut={slidingOut}
                    contentContainerId={contentContainerId}
                    handleIsOpenChange={handleIsOpenChange}
                />
            )}
        </>
    );
}

interface IOrderModal {
    languagePrefix: string;
}

export default OrderModal;
