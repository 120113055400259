import React from 'react';
import styles from './salesTeaser.scss';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import StopWatch from '../../icons/stopwatch';
import { IInjectedProps } from '../../../types';

export const SalesTeaser = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const isRemainingStock = store?.dataStore.orderModule.articleInfo.isRemainingStock;
        const hasRecommendedRetailPrice = store?.dataStore.orderModule.articlePrice.hasRecommendedRetailPrice;
        const hasPriceDeduction = !!store?.dataStore.orderModule.articlePrice.priceDeduction;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const localization = store!.localization;

        if (hasPriceDeduction && isRemainingStock) {
            return (
                <div>
                    <div data-testid='sales-teaser' className={classNames(styles.teaser, styles.sales_teaser)}>
                        <StopWatch />
                        <span>
                            <span>{localization.whileStocksLast}</span>
                        </span>
                    </div>
                </div>
            );
        } else if (hasPriceDeduction && hasRecommendedRetailPrice) {
            return (
                <div>
                    <div data-testid='rrp-teaser' className={classNames(styles.teaser, styles.rrp_teaser)}>
                        <div className={styles.teaser_text}>
                            <span>{localization.rrp}: </span>
                            {localization.priceAdvantage}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    })
);
