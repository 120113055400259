import React from 'react';
import { inject, observer } from 'mobx-react';
import { IExtContentProps } from './extContent.d';

const ExtContent = inject('store')(
    observer(({ store, onHasExtContent }: IExtContentProps) => {
        const extContentRef = React.useRef<HTMLDivElement>(null);
    
        React.useEffect(() => {
            store.orderModuleStore.getExtContentElement();

            if (extContentRef.current && store.orderModuleStore.extContentElement) {
                extContentRef.current.appendChild(store.orderModuleStore.extContentElement);
                typeof onHasExtContent === 'function' && onHasExtContent(true);
            }

            return () => {
                store.orderModuleStore.returnExtContentElement();
                typeof onHasExtContent === 'function' && onHasExtContent(false);
            }
        }, []);
    
        return (
            <div
                role='ext-content'
                data-testid='om-ext-content'
                ref={extContentRef}
            />
        );
    }
));

export default ExtContent;
