import React from 'react';
import styles from './loadingSpinner.scss';

function LoadingSpinner() {
    return (
        <div className={styles.loading_icon} data-testid="loading_spinner">
            <div className={styles.loader}></div>
        </div>
    );
}

export default LoadingSpinner;
