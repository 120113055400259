import React from 'react';

/* eslint-disable */
function BasketSuccessIcon() {
    return (
        <svg role='img' xmlns="http://www.w3.org/2000/svg" width="20.99776" height="20.99677" viewBox="0 0 20.99776 20.99677">
            <path d="M20.36426,8.45283a.89391.89391,0,0,0-1.126.59228L18.14062,12.5788H7.55371L5.209,1.1164A.90014.90014,0,0,0,4.32715.39667H.90039a.89991.89991,0,1,0,0,1.79981H3.59277L5.9375,13.65888a.90014.90014,0,0,0,.88184.71973H18.80371a.89962.89962,0,0,0,.85938-.63282L20.957,9.57929A.9.9,0,0,0,20.36426,8.45283Z" /><path d="M16.37891,15.90839a2.54419,2.54419,0,1,0,2.54492,2.54444A2.54744,2.54744,0,0,0,16.37891,15.90839Zm0,3.688a1.1438,1.1438,0,1,1,1.14453-1.14355A1.14446,1.14446,0,0,1,16.37891,19.59638Z" /><path d="M9.51074,15.90839a2.54419,2.54419,0,1,0,2.544,2.54444A2.54743,2.54743,0,0,0,9.51074,15.90839Zm0,3.688a1.1438,1.1438,0,1,1,1.14356-1.14355A1.14445,1.14445,0,0,1,9.51074,19.59638Z" /><path d="M13.30859,7.16132a.80063.80063,0,0,0,.56543.23389h.02442a.80021.80021,0,0,0,.57422-.26953l5.13379-5.79492A.79972.79972,0,0,0,18.40918.27021L13.83887,5.4289,11.67871,3.26874a.8.8,0,1,0-1.13086,1.13184Z" />
        </svg>
    );
}

export default BasketSuccessIcon;
