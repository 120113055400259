import React from 'react';
import { observer, inject } from 'mobx-react';
import { IVariantStore } from '../../../../types';
import classNames from 'classnames';
import { FitAccuracy } from './fitAccuracy';
import styles from './fitAccuracyWrapper.scss';

export const FitAccuracyWrapper = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {

        const accuracyPosition = variantStore.accuracyPosition;
        return (
            <div
                data-testid='fit_accuracy_wrapper'
                id='fit_accuracy_wrapper'
                className={classNames(styles.fit_accuracy_wrapper, {
                    [styles.show_accuracy]: variantStore.displayFittingAccuracy,
                })}
                style={accuracyPosition.wrapper}
            >
                <FitAccuracy />
                <div
                    data-testid='fit_accuracy_pointer'
                    id='fit_accuracy_pointer'
                    className={classNames(styles.fit_accuracy_pointer, {
                        [styles.fit_accuracy_pointer_hide]:
                            !accuracyPosition.pointer || !variantStore.isVisibleFitAccuracyPointer,
                    })}
                    style={accuracyPosition.pointer}
                />
            </div>
        );
    })
);
