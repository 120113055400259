/* eslint-disable max-len */
import React from 'react';
import defaultStyles from '../../styles/defaults.scss';

const Icon_Play_3D = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
        <circle cx="17.5" cy="17.5" r="17.5" />
        <path fill={defaultStyles.dpWhite}
            d="M14.183,24.97109a1.90282,1.90282,0,0,1-1.90137-1.90137V11.93007a1.90159,1.90159,0,0,1,2.918-1.60644L23.9945,15.893a1.90022,1.90022,0,0,1-.001,3.21289l-8.79395,5.57031A1.90642,1.90642,0,0,1,14.183,24.97109Zm.001-13.14355a.11292.11292,0,0,0-.04981.01367.09275.09275,0,0,0-.05175.08886V23.06972a.09276.09276,0,0,0,.05175.08887.08775.08775,0,0,0,.10254-.00391l8.79492-5.57031v-.16992l-8.79492-5.56934A.08979.08979,0,0,0,14.184,11.82754Z" />
    </svg>
);

export default Icon_Play_3D;
