import React from 'react';
import styles from './notAvailableModal.scss';
import ModalWrapper from '../modalWrapper/modalWrapper';

function NotAvailableModal(props: { isOpen: boolean; onClose(isOpen: boolean) }) {
    const handleCloseClick = React.useCallback(() => {
        props.onClose(false);
    }, []);

    return (
        <ModalWrapper
            isOpen={props.isOpen}
            contentClassNames={styles.not_available_modal_content}
            title={__OMI18n__.note}
            titleClassNames={styles.not_available_modal_title}
            onClose={handleCloseClick}
            content={
                <div className={styles.not_available_modal_body}>
                    <div dangerouslySetInnerHTML={{ __html: __OMI18n__.productNotAvailable }} />
                    <button className={styles.close_button} onClick={handleCloseClick}>
                        {__OMI18n__.buttonClose}
                    </button>
                </div>
            }
        />
    );
}

export default NotAvailableModal;
