import React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import styles from './compareShareBookmarkMobile.scss';
import Cross from '../../icons/cross';
import MoreIcon from '../../icons/moreIcon';
import ShareIcon from '../../icons/shareIcon';
import classNames from 'classnames';

const CompareShareBookmarkMobile = inject('store')(
    observer(({ store }: IInjectedProps) => {
       
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const mpcButtonContainerRef = React.useRef<HTMLDivElement>(null);
        const generalL10n = store?.localization;
        const expanded = store?.orderModuleStore.compareShareBookmarkMobileExpanded;

        const handleExpandClick = React.useCallback(() => {
            if (window.shell && !expanded) {
                // For bookmark
                window.shell.publishTo('legacy.modals.openbyajax.ajaxfinished', null);
                // For mpc
                if (mpcButtonContainerRef.current)
                    window.shell.publishTo('ManualProductComparison.CreateCompareButtonTouchPoints',
                        mpcButtonContainerRef.current);
            }

            store?.orderModuleStore.toggleCompareShareBookmarkMobileExpanded();
        }, [expanded]);

        const handleShareClick = store?.orderModuleStore.handleShareClick;

        const expandButtonClassNames = expanded
            ? [styles.compareShareBookmark_mobile_expand_button, styles.expanded].join(' ')
            : styles.compareShareBookmark_mobile_expand_button;
        const buttonContainerClassNames = expanded
            ? [styles.compareShareBookmark_mobile_button_container, styles.expanded].join(' ')
            : styles.compareShareBookmark_mobile_button_container;

        const showBookmark = !store.isGlobal && store.dataStore.contextData.bookmarkIsAlive;

        return (
            <div
                id='compareShareBookmark-mobile-container'
                data-testid='compareShareBookmark-mobile-container'
                className={classNames(styles.compareShareBookmark_mobile_container, {
                    [styles.compareShareBookmark_mobile_expand]:
                        store?.orderModuleStore.compareShareBookmarkMobileExpanded,
                }, { [styles.compareShareBookmark_hide]: !store.getIsMobileView })}
            >
                <div
                    className={classNames(styles.background_container, {
                        [styles.dark_background]:
                            store.orderModuleStore.compareShareBookmarkMobileExpanded,
                    })}
                />
                <button data-testid='expand-button' className={expandButtonClassNames} onClick={handleExpandClick}>
                    {expanded ? <Cross /> : <MoreIcon />}
                </button>
                <div
                    className={styles.compareShareBookmark_mobile_action_buttons}
                    style={{ pointerEvents: expanded ? 'initial' : 'none' }}
                >
                    <div className={buttonContainerClassNames}>
                        <button
                            onClick={handleShareClick}
                            className={styles.compareShareBookmark_mobile_action_button}
                        >
                            <ShareIcon />
                            {generalL10n.shareButtonLocale}
                        </button>
                    </div>
                    {showBookmark &&
                        <div className={buttonContainerClassNames}>
                            <div className='hidden' data-service-call='bookmark-add-button' />
                        </div>
                    }
                    {store.dataStore.contextData.mpcIsAlive &&
                    <div className={buttonContainerClassNames} ref={mpcButtonContainerRef}>
                        <input
                            type='hidden'
                            name='SalesarticleVariantKey'
                            value={store.dataStore.orderModule.articleInfo.salesArticleVariantKey}
                            autoComplete='off'
                            autoCorrect='off'
                            autoCapitalize='off'
                            spellCheck='false' />
                        <div
                            className='hidden'
                            data-service-call='mpc-compare-button'
                            data-slug={store.dataStore.breadcrumb.category.seoSlug}
                            data-item-origin='OrderModal'
                            data-displayed-in-category-navkey={store.dataStore.breadcrumb.category.navigationKey}
                        ></div>
                    </div> }
                </div>
            </div>
        );
    })
);

export default CompareShareBookmarkMobile;
